<template>
  <div class="deformed noselect">
    <!-- Wrapper Info in Overlay -->
    <div class="infoi" v-show="loading || tiltmeters.length < 2 || someTiltmeterWithoutBaseTilt">
      <h2 v-if="loading">{{ $t("message.loading") }}...</h2>
      <h2 v-if="tiltmeters.length < 2"></h2>
      <h2 v-if="someTiltmeterWithoutBaseTilt">{{ $t("message.someTiltmeterWithoutBaseTilt") }}...</h2>
    </div>
    <div id="header">
      <h2>{{ $t("message.deflectionGraph") + " (" + spanName + " / " + spanLength + "m)" }}</h2>
    </div>
    <!-- Wrapper Info in Overlay -->
    <div class="infoi" v-show="false">
      <h2 v-if="false">{{ $t("message.noDataForSensor") }}...</h2>
      <h2 v-else>{{ $t("message.loading") }}</h2>
    </div>
    <!-- Sensori + plot -->
    <div id="plot-wrap">
      <p class="general-info">{{ $t("message.selectedDevices") + ": " + selectedEUIS.length + "/" + tiltmeters.length }}</p>
      <div id="sensors-list">
        <div :class="[checkIfSelected(tiltmeter.eui) ? 'selected-sensor' : '', 'sensor-item']" v-for="tiltmeter in tiltmeters" :key="tiltmeter.eui" @click="selectSensor(tiltmeter.eui)">
          <label class="container">
            <span class="checkmark abs"></span>
          </label>
          <p>{{ tiltmeter.eui.substr(tiltmeter.eui.length - 5) || " - " }}</p>
        </div>
      </div>
      <div id="lineplot-wrap">
        <!-- Info Grafico -->
        <div class="over-info">
          <h2>{{ $t("message.deflectionGraph") }}</h2>
          <p>{{ $t("message.deflectionDesc") }}</p>
        </div>
        <LinePlot v-if="true" :traces="deformedTrace" :height="500" :xTitle="$t('message.span') + ' [m]'" yTitle="Deflection [m]" />
      </div>
    </div>
    <!-- Lista eventi -->
    <div id="event-list" :class="[liveMode ? 'deactivated' : '']">
      <div id="lock-date">
        <label for="fddEnabled">Live mode:</label>
        <input type="checkbox" name="fddEnabled" class="checkmark" v-model="liveMode" />
      </div>
      <div id="day-select" :class="[liveMode ? 'deactivated' : '']" @click="!liveMode ? (showCalendar = !showCalendar) : null">
        <p class="info-btn">{{ $t("message.date") }}:</p>
        <h2 class="info-btn">{{ formattedDay }}</h2>
        <i class="info-btn" :class="[showCalendar ? 'up' : 'down']"></i>
        <ModalCalendarSingle v-show="showCalendar" :singleDay="true" :dateFromParent="selectedDay" @close="showCalendar = false" @update="updateDate" class="modal-calendar" id="info-deck" />
      </div>
      <h2>{{ $t("title.eventList") }}:</h2>
      <div id="events-container">
        <div :class="[selectedTilt === index ? 'selected-event' : '', 'event-box']" v-for="(tilt, index) in availableTilts" :key="tilt.date" @click="!liveMode ? computeEvent(index) : null">
          <p>{{ formattedDate(tilt.date) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinePlot from "../../graphs/LinePlot.vue";
import api from "../../../services/api";
import ModalCalendarSingle from "../../ui/ModalCalendarSingle.vue";
import amath from "../../../helpers/math";

export default {
  name: "deflection",
  props: {
    structureDetails: Object,
    spanNumber: Number,
    spanLength: Number,
    spanName: String,
  },
  components: {
    LinePlot,
    ModalCalendarSingle,
  },
  data() {
    return {
      someTiltmeterWithoutBaseTilt: false, // Almeno un Tiltmeter non resettato a livello di offset
      polling: null, // setInterval Object
      pollingInterval: 2 * 60 * 1000,
      showCalendar: false, // Modale
      liveMode: false,
      selectedEUIS: [], // Plot
      actualPoints: [], // Plot
      selectedDay: new Date(),
      actualZ: [],
      availableTilts: [],
      selectedTilt: null,
      loading: false,
      maxSelection: 96,
      minSelection: 2,
      /* Trace di base a cui vengono aggiunti attributi in base al grafico */
      baseTrace: {
        connectgaps: true,
        line: {
          color: "rgb(17, 205, 239)",
          width: 4,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 4,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  computed: {
    tiltmeters() {
      let sensors = this.structureDetails.sensors;
      return (
        sensors
          // Filtro solo inclinometri
          .filter((s) => s.type === "tiltmeter" && s.userConfig.spanNumber === this.spanNumber)
          // Li ordino in base alla distanza
          .sort(function(a, b) {
            return a.userConfig.spanDistance > b.userConfig.spanDistance ? 1 : b.userConfig.spanDistance > a.userConfig.spanDistance ? -1 : 0;
          })
      );
    },
    deformedTrace() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.actualPoints;
      basicTrace.x = this.actualZ;
      return [basicTrace];
    },
    formattedDay() {
      return this.selectedDay && this.formatDay(this.selectedDay);
    },
  },
  methods: {
    getEntireDay(date) {
      let firstDate = new Date(date);
      let lastDate = new Date(date);

      firstDate.setMilliseconds(0);
      firstDate.setSeconds(0);
      firstDate.setMinutes(0);
      firstDate.setHours(0);

      lastDate.setMilliseconds(999);
      lastDate.setSeconds(59);
      lastDate.setMinutes(59);
      lastDate.setHours(23);

      return {
        firstDate: firstDate,
        lastDate: lastDate,
      };
    },
    async updateDate(day) {
      this.selectedDay = new Date(day);
      await this.findAvailableEvents();
      if (this.availableTilts.length > 0) {
        this.computeEvent(0);
      }
    },
    pollData() {
      // Primo polling manuale
      this.findAvailableEvents().then((events) => {
        if (events && events.length > 0) {
          this.computeEvent(0);
        } else {
        this.actualPoints = [];
        this.actualZ = [];
      }
      });
      // Dopo chiamo il setInterval
      this.polling = setInterval(() => {
        this.findAvailableEvents().then((events) => {
          if (events && events.length > 0) {
            this.computeEvent(0);
          } else {
            this.actualPoints = [];
            this.actualZ = [];
          }
        });
      }, this.pollingInterval);
    },
    stopPollingData() {
      clearInterval(this.polling);
    },
    formatDay: function(date) {
      let day = date.getDate();
      let year = date.getFullYear();
      let month = date.toLocaleString("default", { month: "short" });
      return day + " " + month + " " + year;
    },
    formattedDate(rawDate) {
      let date = new Date(rawDate);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    },
    checkIfSelected(eui) {
      return this.selectedEUIS.find((item) => item === eui);
    },
    /* Ritorna la lista di tutti gli eventi di quell'ora di tutti i sensori */
    getTiltsByDate(curDate, eventList) {
      const tolerance = 100; // Secondo di tolleranza
      let tilts = [];
      for (let i = 0; i < eventList.length; i++) {
        let sensorCheck = false; // Check per ogni sensore
        let eventsPerSensor = eventList[i];
        // Per ogni tilt del singolo sensore
        for (let j = 0; j < eventsPerSensor.tilts.length; j++) {
          let secondsDiff = new Date(curDate).getTime() - new Date(eventsPerSensor.tilts[j].date).getTime();
          if (Math.abs(secondsDiff / 1000) <= tolerance) {
            sensorCheck = true;
            let baseTiltObj = this.getBaseTiltByEui(eventsPerSensor.eui);
            if (baseTiltObj) {
              tilts.push({
                eui: eventsPerSensor.eui,
                x: eventsPerSensor.tilts[j].x,
                y: eventsPerSensor.tilts[j].y,
                z: eventsPerSensor.tilts[j].z,
                baseTilt: [baseTiltObj.x, baseTiltObj.y, baseTiltObj.z],
              });
            } else {
              this.someTiltmeterWithoutBaseTilt = true;
            }
            break;
          }
        }
        if (!sensorCheck) return false;
      }
      return tilts;
    },
    getBaseTiltByEui(eui) {
      let sensorDetails = this.tiltmeters.find((t) => t.eui === eui);
      return sensorDetails.baseTilt;
    },
    getBeamFormatByEui(eui) {
      let sensorDetails = this.tiltmeters.find((t) => t.eui === eui);
      return sensorDetails.beamFormat;
    },
    /* Trova la lista degli eventi per i quali hanno trasmetti tutti i sensori selezionati */
    async findAvailableEvents() {
      this.loading = true;
      //let date = new Date()
      let sensorEUIS = this.selectedEUIS;
      let date = this.liveMode ? new Date() : new Date(this.selectedDay);
      const day = this.getEntireDay(date);
      let tiltsPromises = sensorEUIS.map((s) => api.getTiltsList(s, day.firstDate, day.lastDate, false, this.getBeamFormatByEui(s)));
      let tilts = await Promise.all(tiltsPromises);
      this.availableTilts = [];
      if (tilts.length > 0) {
        let eventList = tilts
          .filter((t) => t.length > 0)
          .map((t) => ({
            eui: t[0].eui,
            tilts: t.map((event) => ({
              date: event.date,
              x: event.x,
              y: event.y,
              z: event.z,
            })),
          }));
        // Ordino i sensori in basa alla data dell'ultimo evento 
        eventList.sort((a, b) => new Date(a.tilts[0].date).getTime() - new Date(b.tilts[0].date).getTime());  
        if (eventList[0]) {
          for (let i = 0; i < eventList[0].tilts.length; i++) {
            // Per ogni evento del primo sensore controllo l'esistenza degli altri
            let tiltsByDate = this.getTiltsByDate(eventList[0].tilts[i].date, eventList);
            if (tiltsByDate && tiltsByDate.length === this.selectedEUIS.length) {
              this.availableTilts.push({
                date: eventList[0].tilts[i].date,
                tilts: tiltsByDate,
              });
            }
          }
        }
      }
      this.loading = false;
    },
    /* Seleziona/Deseleziona un sensore dato il suo eui */
    async selectSensor(eui) {
      if (!this.checkIfSelected(eui) && this.selectedEUIS.length < this.maxSelection) {
        this.selectedEUIS.push(eui);
      } else if (this.selectedEUIS.length > this.minSelection) {
        const index = this.selectedEUIS.indexOf(eui);
        if (index > -1) {
          this.selectedEUIS.splice(index, 1);
        }
      } else {
        return;
      }
      await this.findAvailableEvents();
      if (this.availableTilts.length > 0) {
        await this.computeEvent(this.selectedTilt || 0);
      } else {
        this.actualPoints = [];
        this.actualZ = [];
      }
    },
    /* Calcola la deformata (API call) */
    async computeEvent(index) {
      this.loading = true;
      this.selectedTilt = index;
      let pkts = this.availableTilts[index].tilts.map((ev) => ({
        eui: ev.eui,
        tilt: amath.getSubAngleTheta(ev.baseTilt || [-1, 0, 0], [ev.x, ev.y, ev.z]),
        distance: this.tiltmeters.find((t) => t.eui === ev.eui).userConfig.spanDistance,
      }));
      let sendPkt = {
        spanLength: this.structureDetails.userConfig.spansDetails[this.spanNumber - 1].length,
        payload: pkts,
      };
      let returnValue = await api.getDeflection(sendPkt);
      this.actualPoints = returnValue.y;
      this.actualZ = returnValue.z;
      this.loading = false;
    },
  },
  async mounted() {
    this.selectedEUIS = this.tiltmeters.map((s) => s.eui);
    if (this.tiltmeters.length >= 2) {
      await this.findAvailableEvents();
      if (this.availableTilts.length > 0) {
        this.computeEvent(this.selectedTilt || 0);
      } else {
        this.actualPoints = [];
        this.actualZ = [];
      }
    }
  },
  watch: {
    liveMode: async function(value) {
      if (value) {
        this.pollData();
      } else {
        this.stopPollingData();
        // Voglio ri-checkare gli eventi con l'orario precedemente settato
        await this.findAvailableEvents();
        if (this.availableTilts.length > 0) {
          this.computeEvent(0);
        } else {
        this.actualPoints = [];
        this.actualZ = [];
      }
      }
    },
  },
  beforeDestroy() {
    this.stopPollingData();
  },
};
</script>

<style scoped>
p {
  font-size: 0.9em;
}
h2 {
  font-size: 0.9em;

  color: rgb(255, 255, 255);
}
h1 {
  color: white;
  font-size: 1em;
  text-align: center;
}
/* Main Wrapper */
.deformed {
  position: relative;
  min-width: 1000px;
  width: 92%;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 40px 10fr;
  margin-left: 4%;
  margin-right: 4%;
  padding-bottom: 1%;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  overflow: hidden;
}

/* Modal */
.modal-calendar {
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(91, 96, 118);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#info-deck {
  position: absolute;
  top: 43px;
  max-width: min-content;
  margin-left: 319px;
  background-color: rgb(45, 48, 65);
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .deformed {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

#header {
  grid-row: 1;
  grid-column: 1/3;
  height: 100%;
  border-bottom: solid 2px white;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-size: 1em;
}

#plot-wrap {
  display: grid;
  grid-column: 2;
  grid-template-rows: max-content 20% 650px;
  padding-right: 60px;
}
#sensors-list {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: auto;
  column-gap: 20px;
  grid-row: 2;
  margin-left: 60px;
  overflow-x: scroll;
  overflow-y: hidden;
}
#lineplot-wrap {
  grid-row: 3;
}

#lock-date {
  color: white;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 30px;
  text-align: left;
  /* border-bottom: solid 2px rgba(255,255,255, 0.5); */
  display: flex;
  align-items: center;
}

#day-select {
  cursor: pointer;
  margin-left: 40px;
  margin-bottom: 40px;
  text-align: left;
  border-bottom: solid 2px white;
  display: flex;
  align-items: center;
}
#day-select p {
  margin-right: 10px;
}
.deactivated {
  cursor: default !important;
}
.deactivated p {
  color: grey;
}
.deactivated > h2 {
  color: grey;
}
.deactivated > i {
  border-color: grey;
}
#event-list {
  grid-row: 2;
  grid-column: 1;
}
#event-list > h2 {
  margin-left: 40px;
  margin-top: 20px;
  text-align: left;
}
.event-box {
  width: 80%;
  background-color: rgb(46, 49, 65);
  border-radius: 5px;
  height: fit-content;
  margin: auto;
  display: grid;
  align-content: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.event-box > p {
  margin: 5px;
}
.selected-event {
  background-color: rgb(21, 146, 230);
}
#events-container {
  margin-top: 20px;
  margin-left: 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 600px;
  padding: 20px;
  background-color: rgb(32, 35, 47);
  border-radius: 10px;
  border: solid 2px white;
}

/* Info in alto a sinistra sul tipo di grafico */
.over-info > h2 {
  font-size: 1em;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  font-size: 0.8em;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: 100%;
}

.sensor-item {
  color: white;
  margin: auto;
  grid-row: 1;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  display: grid;
  justify-items: center;
  align-content: center;
  cursor: pointer;
}
.sensor-item > p {
  margin: 0;
}
.selected-sensor {
  background-color: rgb(21, 146, 230);
}
.general-info {
  color: rgb(198, 198, 198);
  margin-left: 60px;
  text-align: left;
}
/* The container */
.container {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: solid 0.1em white;
  border-radius: 5px;
  box-sizing: border-box;
}

.abs {
  position: absolute;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #ccc;
}
 */
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.selected-sensor > .container > .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 12x;
  background-color: rgba(32, 34, 47, 1);
}

::-webkit-scrollbar {
  height: 14px;
  padding-top: 5px;
  background-color: rgba(32, 34, 47, 1);
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(80, 84, 105, 1);
}
</style>
