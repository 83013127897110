<template>
  <transition name="modal-info-fade">
    <div class="modal-info-backdrop">
      <div class="modal-info" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <div class="calendar-wrapper">
          <a @click="closeModal" class="close"></a>

          <!-- Indicatori del range selezionato -->
          <div class="days-indicator" @click.stop>
            <div class="day-box">
              <h2>{{ $t("message.startDate") }}</h2>
              <div class="static-picker">
                <p>{{ formattedDate }}</p>
                <img class="calendar-img" src="../../../public/assets/calendar.svg" />
              </div>
            </div>
          </div>
          <!-- Main Date Picker Component-->
          <div class="warp" @click.stop>
            <vc-date-picker
              :locale="this.$root.$i18n.locale"
              ref="calendar"
              v-model="date"
              :input-debounce="500"
              color="blue"
              is-dark
              :is-inline="true"
              :rows="1"
              :columns="$screens({ default: 2, lg: 2 })"
              :max-date="new Date()"
              :is-range="!singleDay"
            />
          </div>
          <!-- Bottoni di controllo -->
          <div class="bottom-buttons">
            <button class="button" @click="closeModal">{{ $t("message.undo") }}</button>
            <button class="button" @click="updateDate">{{ $t("message.update") }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from "moment";
import dates from "../../helpers/dates";

export default {
  name: "modal-calendar",
  props: {
    classe: String,
    dateFromParent: Date,
    singleDay: Boolean
  },
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    closeModal: function() {
      this.$emit("close");
    },
    updateDate: function() {
      this.$emit("update", this.date);
    },
  },
  computed: {
    /* firstDate: function() {
      return moment(this.range["start"]).format("YYYY-MM-DD");
    },
    lastDate: function() {
      return moment(this.range["end"]).format("YYYY-MM-DD");
    }, */
    formattedDate: function() {
      return dates.ddmmyyyyFormat(this.date);
    },
  },
  watch: {
    dateFromParent: {
      /* Aggiorno la data */
      handler: function(value) {
        if (value) {
          this.date = this.dateFromParent;
        }
      },
    },
    datarange: {
      /* handler: function(val) {
        this.range.start = val[0];
        this.range.end = val[1];
      } */
    },
    range: {
      handler: function() {
        let fixedDateRange = dates.getEntireDateRange(this.date, this.date);
        this.date = fixedDateRange[0];
        this.$emit("date-selected", moment(this.date));
      },
    },
  },
};
</script>
<style scoped>
/* MODAL STUFF */
.modal-info-backdrop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  z-index: 999;
  padding: 0 30px;
}
.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 86.6px 50px 0;
  border-color: transparent #2d3041 transparent transparent;
}

.modal-info-header {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.modal-info-header {
  padding-top: 10px;
  color: #4aae9b;
  justify-content: space-between;
  background-color: rgb(45, 48, 65);
}

.modal-info-body {
  position: relative;
  background-color: rgb(45, 48, 65);
}

.btn-close {
  border: none;
  padding: 50px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  background: #4aae9b;
  border-radius: 2px;
  width: 400px;
  height: 40px;
  font-size: 20px;
}

.modal-info-fade-enter,
.modal-info-fade-leave-active {
  opacity: 0;
}

.modal-info-fade-enter-active,
.modal-info-fade-leave-active {
  transition: opacity 0.3s ease;
}

h2 {
  font-size: 1em;
  color: white;
  margin: 0;
}

/* CALENDAR STUFF */

/* Root Wrapper */
.calendar-wrapper {
  height: 470px;
  width: fit-content;
  min-height: 0px;
  display: flex;
  border-radius: 10px;
  z-index: 999999999;
}

/* Calendar Component */
.vc-container {
  background-color: rgba(80, 84, 105, 0) !important;
  border: hidden !important;
  margin-top: 125px;
  margin-left: 15px;
}

/* Bottoni scelta giorni */
.days-control {
  display: grid;
  justify-items: baseline;
  margin-left: 22px;
  margin-right: 15px;
  height: 280px;
  margin-top: 30px;
}

.unstyled-button {
  color: gray;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 0;
  background: none;
}

.unstyled-button:focus {
  outline: 0 !important;
}

.active {
  color: white;
}
.static-picker > p {
  font-weight: 600;
  margin: auto;
  left: -20px;
  position: relative;
}

/* Visualizzazione date scelte */
.days-indicator {
  position: absolute;
  min-height: 0px;
  top: 42px;
  left: 60px;
  width: 380px;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.day-box {
  margin-right: auto;
  text-align: left;
}
.static-picker {
  width: 160px;
  height: 38px;
  background-color: rgba(80, 84, 105, 1);
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: default;
  min-width: 0%;
  min-height: 0px;
}
.calendar-img {
  position: absolute;
  margin-left: 130px;
}

/* Utils */
.horizontal-divider {
  margin-top: 20px;
  margin-bottom: auto;
  height: 400px;
  width: 2px;
  background-color: rgba(80, 84, 105, 1);
}

/* Control Buttons */
.button {
  width: 118px;
  font-size: 1em;
  padding: 4px;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}

.bottom-buttons {
  right: 0px;
  position: absolute;
  bottom: 20px;
}

/* Close Icon */
.close {
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 9;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>
