<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:content>
        <!-- Deformata stativa -->
        <div class="sub-wrapper" v-if="getStructureDetails && getStructureDetails._id">
          <Deflection v-for="(span, index) in spans" :spanNumber="index + 1" :spanName="span.name" :spanLength="span.length" :key="index" :structureDetails="getStructureDetails" />
        </div>
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import PageTemplate from "../PageTemplate.vue";
import Deflection from "../../components/modules/dashboard/Deflection.vue";

export default {
  name: "LoadTestV2",
  components: {
    PageTemplate,
    Deflection,
  },
  data() {
    return {
      loaded: true,
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSensorsPool: "trend/setSensorsPool",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      setDateRange: "trend/setDateRange",
      fetchTrendAPI: "trend/fetchTrendAPI",
    }),
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorPool: "trend/getSensorPool",
      getDateRange: "trend/getDateRange",
      getUserType: "user/getUserType",
    }),
    spans() {
      return this.getStructureDetails && this.getStructureDetails.userConfig ? this.getStructureDetails.userConfig.spansDetails : [];
    },
  },
};
</script>
<style scoped></style>
